import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./redux/api/apiSlice";
import authSliceReducer from "./redux/auth/authSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import loginSliceReducer from "./redux/login/authSlice";
import ecommerceSliceReducer from "./redux/ecommerce/ecommerceSlice";

import OrderReducer from "./redux/reducers/OrderReducer";

import ServiceProviderReducer from "./redux/reducers/ServiceProviderReducer";
import ServiceTypeReducer from "./redux/reducers/ServiceTypeReducer";
import ServiceOrderReducer from "./redux/reducers/ServiceOrderReducer";
import ServiceReducer from "./redux/reducers/ServiceReducer";
import ServiceProviderTypeReducer from "./redux/reducers/ServiceProviderTypeReducer";
import ServiceInfoReducer from "./redux/reducers/ServiceInfoReducer";
import ServiceInfoTypeReducer from "./redux/reducers/serviceInfoTypeReducer";
import ServiceIncludeReducer from "./redux/reducers/ServiceIncludeReducer";
import AvailableServiceProviderReducer from "./redux/reducers/AvailableServiceProviderReducer";
import TimeSoltReducer from "./redux/reducers/TimeSoltReducer";
import ServiceDetailsReducer from "./redux/reducers/ServiceDetailsReducer";
import ZoneReducer from "./redux/reducers/ZoneReducer";
import CountryReducer from "./redux/reducers/CountryReducer";
import categoryReducer from "./redux/reducers/CategoryReducer";
import StateReducer from "./redux/reducers/StateReducer";
import CityReducer from "./redux/reducers/CityReducer";
import PermissionReducer from "./redux/reducers/PermissionReducer";
import SubServiceReducer from "./redux/reducers/SubServiceReducer";
import UserReducer from "./redux/reducers/UserReducer";
import UserTypeReducer from "./redux/reducers/UserTypeReducer";
import ReportOrderReducer from "./redux/reducers/ReportOrderReducer";
import ServiceStatusReducer from "./redux/reducers/ServiceStatusReducer";
import ReportServiceOrderReducer from "./redux/reducers/ReportServiceOrderReducer";
import OrderStatusReducer from "./redux/reducers/OrderStatusReducer";
import ServiceAssignReducer from "./redux/reducers/ServiceAssignReducer";
import DashboardReducer from "./redux/reducers/DashboardReducer";
import AssignCityReducer from "./redux/reducers/AssignCityReducer";
import ServiceGroupReducer from "./redux/reducers/ServiceGroupReducer";
import SosServicesReducer from "./redux/reducers/SosServiceReducer";


//----



export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    ecommerce: ecommerceSliceReducer,


    
    orderReducer: OrderReducer,
    serviceOrderReducer: ServiceOrderReducer,
    serviceProviderReducer: ServiceProviderReducer,
    timeSoltReducer: TimeSoltReducer,
    categoryReducer: categoryReducer,
    serviceTypeReducer: ServiceTypeReducer,
    serviceReducer: ServiceReducer,
    serviceProviderTypeReducer: ServiceProviderTypeReducer,
    zoneReducer: ZoneReducer,
    countryReducer: CountryReducer,
    stateReducer: StateReducer,
    serviceDetailsReducer: ServiceDetailsReducer,
    cityReducer: CityReducer,
    serviceInfoReducer: ServiceInfoReducer,
    serviceInfoTypeReducer: ServiceInfoTypeReducer,
    serviceIncludeReducer: ServiceIncludeReducer,
    availableServiceProviderReducer : AvailableServiceProviderReducer,
    permissionReducer: PermissionReducer,
    subServiceReducer: SubServiceReducer,
    userReducer : UserReducer,
    userTypeReducer : UserTypeReducer,
    reportOrderReducer : ReportOrderReducer,
    serviceStatusReducer : ServiceStatusReducer,
    reportServiceOrderReducer : ReportServiceOrderReducer,
    orderStatusReducer : OrderStatusReducer,
    serviceAssignReducer : ServiceAssignReducer,
    dashboardReducer: DashboardReducer,
    assignCityReducer: AssignCityReducer,
    serviceGroupReducer: ServiceGroupReducer,
    sosServiceReducer:SosServicesReducer,

  },
  // RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares({serializableCheck: false}).concat(apiSlice.middleware),
});
