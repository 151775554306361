import { baseURL, ports } from "app/utils/constant";
import axios from "axios";

function getBaseUrl(url, type) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL  
  return url;
  /* if (url === "login") {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("access-management")) {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("home-management")) {    
    axios.defaults.baseURL = baseURL+ports.home;    
  }
  else if (url.includes("report-management")) {    
    axios.defaults.baseURL = baseURL+ports.report;    
  }
  else {
    axios.defaults.baseURL = baseURL+ports.payroll;    
  }
  return url; */
}

export function getAPIUrl(url) {
  let baseURLVal = baseURL+ports.payroll;
  baseURLVal = baseURLVal+url
  return baseURLVal;
}

export function returnApiCallPromise(method, url, object = null, config = null) {  
  //alert(process.env.REACT_APP_API_URL)
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  axios.defaults.headers['unitid'] = 1;
  axios.defaults.headers['type'] = "web";
  axios.defaults.headers['zoneid'] = "1";//to get service list(without zone id service list will become empty in future we need to remove this)
  axios.defaults.headers['user_id'] = 1;
  axios.defaults.headers['product'] = "kp";

  //axios.create({withCredentials : true});
  // axios.defaults.headers['org_id'] = global.OrgID ? global.OrgID : 0;
  // axios.defaults.headers['branchid'] = global.branchId ? global.branchId : 0;
  // axios.defaults.headers['usercode'] = global.usercode;  
  // axios.defaults.headers['counterid'] = global.counterId ? global.counterId : 0;
  // eslint-disable-next-line default-case
  switch (method) {
    case 'GET':      
      return axios.get(getBaseUrl(url), object, config);
    case 'POST':      
      return axios.post(getBaseUrl(url), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url), object, config);
    case 'FORMPUT':
      const configs = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.put(getBaseUrl(url), object, configs);
    case 'FORMPOST':
      const postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.post(getBaseUrl(url), object, postConfig);
  }
}
