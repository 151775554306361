import React from 'react';
import AuthGuard from 'app/auth/AuthGuard'
import categoryRoutes from "app/views/category/categoryRoutes";
import serviceOrderRoutes from "app/views/service-order/serviceOrderRoutes";
import serviceProviderRoutes from "app/views/service-provider/service-provider-route";
import serviceTypeRoutes from "app/views/service-type/service-type-route";
import serviceRoutes from "app/views/service/serviceRoutes";
import serviceInfoRoutes from "app/views/service-info/service-info-route";
import serviceIncludeRoutes from "app/views/service-include/service-include-route";
import ZoneRoutes from "app/views/zone/zoneRoute";

import sessionsRoutes from "app/views/sessions/sessionsRoutes";
import dataTableRoute from "app/views/dataTable/dataTableRoute";
import invoiceRoutes from "app/views/app/invoice/invoiceRoutes";
import inboxRoutes from "app/views/app/inbox/inboxRoutes";
import chatRoutes from "app/views/app/chat/chatRoutes";
import calendarRoutes from "app/views/app/calendar/calendarRoutes";
import taskManagerRoutes from "app/views/app/task-manager/taskManagerRoutes";
import contactRoutes from "app/views/app/contact/contactRoutes";
import Error404 from "app/views/sessions/Error";
import userRoutes from "app/views/user/user-route";

import dashboardRoutes from "app/views/dashboard/dashboardRoutes";
import chartsRoute from "app/views/charts/chartsRoute";
import reportOrderRoutes from "app/views/report-order/reportOrderRoutes";
import reportServiceOrderRoutes from "app/views/report-service-order/categoryRoutes";
import cityRoutes from "app/views/city/cityRoutes";


import { Navigate, Outlet } from 'react-router-dom'
import GullLayout from 'app/GullLayout/GullLayout';
import accountSettingsRoutes from 'app/views/accountSetting/accountSettingsRoutes';
import serviceGroupRoutes from 'app/views/service-group/serviceGroupRoutes';

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard >
                    <GullLayout>
                        <Outlet />
                    </GullLayout>                    
                </AuthGuard>
            ),
            children: [...serviceOrderRoutes, ...serviceProviderRoutes, ...serviceTypeRoutes, ...categoryRoutes, ...serviceRoutes, ...userRoutes,
            ...serviceInfoRoutes, ...serviceIncludeRoutes, ...ZoneRoutes, ...dataTableRoute, ...invoiceRoutes, ...inboxRoutes, ...calendarRoutes,
            ...taskManagerRoutes, ...contactRoutes, ...chatRoutes, ...dashboardRoutes, ...chartsRoute, ...reportOrderRoutes, ...reportServiceOrderRoutes,
            ...cityRoutes,...accountSettingsRoutes,...serviceGroupRoutes],
        },
        ...sessionsRoutes,
        {
            path: '/',
            // element: <Navigate to="dashboard/default" />,
            element: <Navigate to="dashboard" />,
        },
        {
            path: '*',
            element: <Error404 />,
        },
    ]

    return all_routes
}
